<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">ポイント購入設定</span>
      </div>
      <form
        class="cpx-15"
        @submit.prevent="CreatePurchasePoint()"
        :disabled="isLoading"
      >
        <!-- Step 1 -->
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            購入ボーナス（講入毎】
          </label>
        </div>
        <div class="col-sm-12 col-lg-12">
          <div :class="form_style">
            <div class="step_header">
              <span class="span_set span_style">
                <button
                  type="button"
                  @click="toggleShow('set', 1)"
                  :class="[
                    btn_style,
                    step_1_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定する
                </button>
                <button
                  type="button"
                  @click="toggleShow('not_set', 1)"
                  :class="[
                    btn_style,
                    !step_1_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定しない
                </button>
              </span>
            </div>
            <div v-if="step_1_setting" :class="[input_style]">
              <input
                class="form-input input-class px-2"
                type="number"
                name="step_1_point"
                id=""
                v-model.number="step_1_point"
                :min="0"
                onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              />
              <p class="m-0">ポイント購入毎</p>
              <input
                class="form-input input-class px-2"
                type="number"
                name="step_1_bonus"
                id=""
                v-model.number="step_1_bonus"
                :min="0"
                onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              />
              <p class="m-0">プレゼント</p>
            </div>
          </div>
        </div>
        <!-- Step 2 -->
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            購入ボーナス (%アップ)
          </label>
        </div>
        <div class="col-sm-12 col-lg-12">
          <div :class="form_style">
            <div class="step_header">
              <span class="span_set span_style">
                <button
                  type="button"
                  @click="toggleShow('set', 2)"
                  :class="[
                    btn_style,
                    step_2_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定する
                </button>
                <button
                  type="button"
                  @click="toggleShow('not_set', 2)"
                  :class="[
                    btn_style,
                    !step_2_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定しない
                </button>
              </span>
            </div>
            <div v-if="step_2_setting" :class="[input_style]">
              <p class="ml-2 m-0">購入額の</p>
              <input
                class="form-input input-class px-2"
                type="number"
                name="step_2_up"
                id=""
                v-model.number="step_2_up"
                :min="0"
                onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
              />
              <p class="m-0">% ポイントUP</p>
            </div>
          </div>
        </div>
        <!-- Step 3 -->
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            購入ボーナス (段階%アップ)
          </label>
        </div>
        <div class="col-sm-12 col-lg-12">
          <div :class="form_style">
            <div class="step_header">
              <span class="span_set span_style">
                <button
                  type="button"
                  @click="toggleShow('set', 3)"
                  :class="[
                    btn_style,
                    step_3_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定する
                </button>
                <button
                  type="button"
                  @click="toggleShow('not_set', 3)"
                  :class="[
                    btn_style,
                    !step_3_setting ? 'btn-danger' : 'btn-secondary',
                  ]"
                >
                  設定しない
                </button>
              </span>
            </div>
            <div v-if="step_3_setting" :class="[input_style]">
              <ul class="p-0">
                <li
                  v-for="(value, index) in step_3_column"
                  :key="index"
                  :class="['px-0', 'li-class', 'mt-3']"
                >
                  <button
                    class="btn btn-danger remove-button"
                    type="button"
                    @click="removeItem(index)"
                  >
                    -
                  </button>
                  <span class="span_style">
                    <input
                      class="form-input input-class px-2"
                      type="number"
                      name="step_3_point"
                      id=""
                      v-model.number="value.step_3_point"
                      :min="0"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    />
                    <p class="m-0">ポイント以上の購入は</p>
                    <input
                      class="form-input input-class px-2"
                      type="number"
                      name="step_3_bonus"
                      id=""
                      v-model.number="value.step_3_bonus"
                      :min="0"
                      onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    />
                    <p class="m-0">% ポイントUP</p>
                  </span>
                </li>
              </ul>
            </div>
            <button
              v-if="step_3_setting"
              type="button"
              @click="addNewSetting"
              :class="[btn_style, 'btn-success', 'px-5', 'mt-3']"
            >
              追加
            </button>
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button
            class="align-items-center btn btn-info btn-round m-3 px-3"
            :disabled="isLoading"
          >
            <span v-show="!isLoading">保存</span>
            <div
              v-show="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">ローディング...</span>
            </div>
          </button>
          <button
            type="button"
            @click="returnPage"
            class="align-items-center btn btn-secondary btn-round m-3 px-3 back-btn"
          >
            戻る
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      no: "",
      isLoading: false,
      listContentOptions: [],
      list_content: [],
      errorContentIdRequired: false,
      loadingContent: false,
      shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      page: 2,
      NO: "",
      id: this.$route.params.id,
      //Thinh's:
      //Dynamic styles:
      form_style: "form-group mb-5",
      input_style: "create-point form-inline justify-content-left",
      btn_style: "btn btn-round",
      span_style: "d-flex align-items-center my-2",
      //End of dynamic styles
      step_1_setting: 0,
      step_1_point: "",
      step_1_bonus: "",
      step_2_setting: 0,
      step_2_up: "",
      step_3_setting: 0,
      step_3_column: [
        {
          step_3_point: "",
          step_3_bonus: "",
        },
      ],
    };
  },
  created() {
    this.NO = this.$route.query.no;
    const data = {
      // id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    this.getPurchasePointById(data);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "PurchasePointById"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    PurchasePointById() {
      // this.price = this.PurchasePointById.price;
      // this.point = this.PurchasePointById.point;
      this.step_1_setting = this.PurchasePointById.step_1_setting;
      this.step_1_point = this.PurchasePointById.step_1_point;
      this.step_1_bonus = this.PurchasePointById.step_1_bonus;
      this.step_2_setting = this.PurchasePointById.step_2_setting;
      this.step_2_up = this.PurchasePointById.step_2_up;
      this.step_3_setting = this.PurchasePointById.step_3_setting;
      this.step_3_column =
        JSON.parse(this.PurchasePointById.step_3_column) || [];
    },
  },
  methods: {
    ...mapActions({ getPurchasePointById: "getPurchasePointById" }),
    async CreatePurchasePoint() {
      this.isLoading = true;
      let check_col = this.step_3_column.some((obj) => {
        return obj.step_3_point <= 0 || obj.step_3_bonus <= 0;
      });
      if (this.step_1_setting && this.step_1_point <= 0) {
        this.$toasted.error(
          "ステップ1のポイントは0より大きくなければなりません"
        );
        this.isLoading = false;
        return;
      }
      if (this.step_1_setting && this.step_1_bonus <= 0) {
        this.$toasted.error(
          "ステップ1のボーナスは0より大きくなければなりません"
        );
        this.isLoading = false;
        return;
      }
      if (this.step_2_setting && this.step_2_up <= 0) {
        this.$toasted.error(
          "ステップ2のボーナスは0より大きくなければなりません"
        );
        this.isLoading = false;
        return;
      }
      if (this.step_3_setting && check_col) {
        this.$toasted.error("ステップ3の入力値は0より大きくなければなりません");
        this.isLoading = false;
        return;
      }
      const id = this.id;
      const formdata = {
        id: id,
        shop_id: this.shop_id,
        step_1_setting: this.step_1_setting,
        step_1_point: this.step_1_point,
        step_1_bonus: this.step_1_bonus,
        step_2_setting: this.step_2_setting,
        step_2_up: this.step_2_up,
        step_3_setting: this.step_3_setting,
        step_3_column: this.step_3_column,
      };
      const dataReturn = await this.$store.dispatch(
        "updatePurchasePoint",
        formdata
      );
      if (dataReturn.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      } else {
        this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
    returnList() {
      this.$router.go(-1);
    },
    toggleShow(action, id) {
      let val = 0;
      if (action == "set") {
        val = 1;
      }
      this[`step_${id}_setting`] = val;
    },
    addNewSetting() {
      this.step_3_column.push({
        step_3_point: "",
        step_3_bonus: "",
      });
    },
    removeItem(index) {
      this.step_3_column.splice(index, 1);
    },
    returnPage() {
      this.$router.go(-1);
    },
    // demoMethod() {
    //   console.log("Submit");
    //   // let finalData = {
    //   //   shop_id: this.shopId,
    //   //   step_1_setting: this.step_1_setting,
    //   //   step_1_point: this.step_1_point,
    //   //   step_1_bonus: this.step_1_bonus,
    //   //   step_2_setting: this.step_2_setting,
    //   //   step_2_up: this.step_2_up,
    //   //   step_3_setting: this.step_3_setting,
    //   //   step_3_column: this.step_3_column,
    //   // };
    //   console.log(this.$route.params);
    // },
  },
};
</script>

<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.create-point {
  padding: 20px 0 20px 0;
}
.step_header {
  /* d-flex justify-content-between align-items-center */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.span_set {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.span_style {
  align-items: center;
}
.li-class {
  display: flex;
}
.remove-button {
  padding-top: auto;
  padding-bottom: auto;
}

/* input {
  width: 7em;
  padding: auto;
}

input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
} */

.input-class {
  width: 7em;
  padding: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 605px) {
  .form-inline {
    display: block;
  }
  .span-input {
    margin-left: 0;
    margin-right: 0;
  }
  li button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .input-class {
    width: 100%;
  }
  .li-class {
    display: block;
  }

  ul {
    padding-inline-start: 0;
  }

  .create-point {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 350px) {
  .btn {
    font-size: 0.7rem;
  }
  ul {
    padding: 0;
  }
}

@media only screen and (min-width: 606px) and (max-width: 767px) {
  /**/
  li button {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .form-inline {
    display: block;
  }
  .input-class {
    width: 100%;
  }
  .li-class {
    display: block;
  }

  ul {
    padding-inline-start: 0;
  }

  .create-point {
    padding-left: 0;
    padding-right: 0;
  }
  .span-input {
    margin-left: 0;
    margin-right: 0;
  }
  /**/
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  div .span_style {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .span_style {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }
}

@media only screen and (min-width: 994px) and (max-width: 1199px) {
  div .span_style {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .span_style {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1452px) {
  /* div span {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  } */

  button {
    margin-left: 1rem;
    /* margin-right: 0.5rem; */
  }
  /**/
  li button {
    margin-left: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .form-inline {
    display: block;
  }
  .input-class {
    width: 100%;
  }
  .li-class {
    display: block;
  }

  ul {
    padding-inline-start: 0;
  }

  .create-point {
    padding-left: 0;
    padding-right: 0;
  }
  .span-input {
    margin-left: 0;
    margin-right: 0;
  }
  .input-class {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  /**/
}

@media only screen and (min-width: 1453px) {
  /* Step 3 style*/
  button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .span_style {
    display: flex;
    align-items: center;
    margin-right: 2rem;
  }
  /* Step 3 style*/
}

@media only screen and (max-width: 767px) {
  .span_set {
    margin-right: 0;
  }
  span button {
    width: 10rem;
    height: 4rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
  }
  .step_header {
    display: block;
  }
  label {
    width: 80%;
    margin-right: 0.5rem;
  }
  .input-class {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>
